import { action, computed, makeObservable, observable } from "mobx"

class ParallaxStore {
	@observable
	private _top
		: number
		= 0

	@action
	private setTop = () => {
		this._top = window.scrollY
	}

	constructor() {
		makeObservable(this)
	}

	@computed
	get top() {
		return this._top
	}

	subscribe = () => {
		document.addEventListener("scroll", this.setTop)
	}

	unsubscribe = () => {
		document.removeEventListener("scroll", this.setTop)
	}
}

export default new ParallaxStore()