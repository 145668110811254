import React from "react"

import "styles/components/parallax/instances/layer-big-drops"

import ParallaxLayer from "../Layer"
import Routing from "stores/Routing"
import { randomInt } from "utils/dev"

export default
class ParallaxLayerBigDrops
extends React.Component<{}, {}> {
	items
		: {
			name: string,
			top: number
			left?: number
			right?: number
			delay: string
			duration: string
		}[]
		= [
			{
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_9",
				top: 183,
				left: 550,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_1",
				top: 680,
				right: 120,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_10",
				top: 860,
				left: 256,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_1",
				top: 1515,
				left: 493,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_10",
				top: 2202,
				right: 601,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_9",
				top: 3200,
				left: 550,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_6",
				top: 3481,
				right: 438,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_9",
				top: 4199,
				left: 320,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_6",
				top: 4389,
				right: 450,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_10",
				top: 4601,
				left: 249,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_6",
				top: 5092,
				right: 438,
			},
			{
				
				delay: -(randomInt([50, 450])) + "ms",
				duration: randomInt([6000, 12000]) + "ms",
				name: "drop_9",
				top: 6900,
				left: 550,
			},
		]
	
	render() {
		return <>
			<ParallaxLayer
				scrollFactor={.48}
			>
				{this.items.map((item, i) => {
					return <img
						key={i}
						className="c-pl-big-drops-item"
						src={Routing.static(`images/fx/${item.name}.png`)}
						style={{
							top: item.top,
							left: item.left,
							right: item.right,
							animationDelay: item.delay,
							animationDuration: item.duration,
						}}
					/>
				})}
			</ParallaxLayer>
		</>
	}
}