import React from "react"

import "styles/views/home/components/sections/main"

import GradientText from "components/Utils/GradientText"
import Routing from "stores/Routing"
import { randomInt } from "utils/dev"
import ScalableImage from "components/Utils/ScalableImage"

export interface HomeMainSectionProps {

}

export interface HomeMainSectionState {

}

export default
class HomeMainSection
extends React.Component<HomeMainSectionProps, HomeMainSectionState> {
	drops
		: {
			name: string
			top: number | string
			left?: number | string
			right?: number | string
		}[]
		= [
			{
				name: "drop_3",
				top: 9,
				left: 87,
			},
			{
				name: "drop_4",
				top: 97,
				left: 123,
			},
			{
				name: "drop_4",
				top: 50,
				left: 235,
			},
		]

	render() {
		return <>
			<section className="c-home-main">
				<div className="hms-inner-wrapper">
					<h1>
						<div className="hms-row">
							<GradientText>
								Grow crypto network
							</GradientText>
							<div className="hms-fx">
								{[...Array(4)].map((_, i) => {
									return <ScalableImage
										key={i}
										path={`main_${i + 1}`}
										scales={[2]}
									/>
								})}
								{this.drops.map((item, i) => {
									return <img
										key={i}
										className="dust"
										src={Routing.static(`images/fx/${item.name}.png`)}
										style={{
											top: item.top,
											left: item.left,
											right: item.right,
											animationDelay: -(randomInt([50, 450])) + "ms",
											animationDuration: randomInt([3600, 6000]) + "ms",
										}}
									/>
								})}
							</div>
						</div>
						<div className="hms-row">
							<span>
								Meet like-minded friends
							</span>
						</div>
						<div className="hms-row">
							<GradientText>
								Build own community
							</GradientText>
						</div>
						<ScalableImage
							path="fx/drops_main"
							scales={[2]}
							className="hms-big-drops"
						/>
					</h1>
					<p>
						MeetSquad is a decentralised Platform, which provides crypto community a smooth way to grow their network and find like-minded individuals 
					</p>
					<div className="hms-next">
						<ScalableImage
							path="fx/fx_next"
							scales={[2]}
						/>
					</div>
				</div>
			</section>
		</>
	}
}