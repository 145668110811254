import React from "react"

import { HOSSceneProps } from "../sections/Overview"
import { HOSKeyframe, interpolate } from "."

export default
class HOSRipple
extends React.Component<HOSSceneProps, any> {
	keyframes
		: HOSKeyframe[]
		= [
			[0, {
				top: [395],
				left: [180],
				scale: [0],
				innerBoxShadow: [22],
			}],
			[.32, {
				top: [395],
				left: [180],
				scale: [0],
				innerBoxShadow: [22],
			}],
			[.34, {
				top: [395],
				left: [180],
				scale: [1, "easeOutCubic"],
				innerBoxShadow: [22],
			}],
			[.39, {
				top: [395],
				left: [180],
				scale: [1],
				innerBoxShadow: [0, "easeOutCubic"],
			}],
			[.43, {
				top: [325, "stepIn"],
				left: [160, "stepIn"],
				scale: [0, "stepIn"],
				innerBoxShadow: [22],
			}],
			[.45, {
				top: [325],
				left: [160],
				scale: [0],
				innerBoxShadow: [22],
			}],
			[.47, {
				top: [325],
				left: [160],
				scale: [1, "easeOutCubic"],
				innerBoxShadow: [22],
			}],
			[.56, {
				top: [325],
				left: [160],
				scale: [1],
				innerBoxShadow: [0, "easeOutCubic"],
			}],
			[.8, {
				top: [100, "stepIn"],
				left: [200, "stepIn"],
				scale: [0, "stepIn"],
				innerBoxShadow: [22],
			}],
			[.82, {
				top: [100],
				left: [200],
				scale: [0],
				innerBoxShadow: [22],
			}],
			[.84, {
				top: [100],
				left: [200],
				scale: [1, "easeOutCubic"],
				innerBoxShadow: [22],
			}],
			[.9, {
				top: [100],
				left: [200],
				scale: [1],
				innerBoxShadow: [0, "easeOutCubic"],
			}],
		]

	render() {
		const {
			top: _top,
			left: _left,
			scale,
			innerBoxShadow,
		} = interpolate(this.props.progress, this.keyframes)

		const [ top ] = _top!
		const [ left ] = _left!

		return <>
			<div
				className="ripple"
				style={{
					position: "absolute",
					top,
					left,
					boxShadow: `inset 0 0 0 ${innerBoxShadow![0]}px white`,
					transform: `scale(${scale}) translateZ(0)`,
				}}
			/>
		</>
	}
}