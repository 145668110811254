import React from "react"

import "styles/views/home/components/sections/coming-soon"

import ScalableImage from "components/Utils/ScalableImage"

export interface HomeComingSoonSectionProps {
	
}

export interface HomeComingSoonSectionState {
	
}

export default
class HomeComingSoonSection
extends React.Component<HomeComingSoonSectionProps, HomeComingSoonSectionState> {
	render() {
		return <>
			<section className="c-coming-soon">
				<div className="hcss-content">
					<h2>
						Coming soon 👀
					</h2>
					<div className="hcss-graphics">
						<ScalableImage path="mockups/coming_soon_1" scales={[2]} />
						<ScalableImage path="mockups/coming_soon_2" scales={[2]} />
					</div>
				</div>
			</section>
		</>
	}
}