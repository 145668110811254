import React from "react"

import "styles/components/ui/labels/simple"

export interface SimpleLabelProps {
	children: React.ReactNode
}

export interface SimpleLabelState {
	
}

export default
class SimpleLabel
extends React.Component<SimpleLabelProps, SimpleLabelState> {
	render() {
		return <>
			<div className="c-simple-label">
				{this.props.children}
			</div>
		</>
	}
}