import React from "react"
import { observer } from "mobx-react"

import "styles/views/home/components/sections/tokenomics"

import Routing from "stores/Routing"
import Meta from "stores/Meta"

import GradientText from "components/Utils/GradientText"

export interface HomeTokenomicsSectionProps {

}

export interface HomeTokenomicsSectionState {
	hightlighted?: string
}

@observer
export default
class HomeTokenomicsSection
extends React.Component<HomeTokenomicsSectionProps, HomeTokenomicsSectionState> {
	state
		: HomeTokenomicsSectionState
		= {
			hightlighted: undefined,
		}

	items
		: {
			id: string
			caption: string
			value: number
		}[]
		= [
			{
				id: "seed",
				caption: "Seed sale",
				value: 10,
			},
			{
				id: "private",
				caption: "Private sale",
				value: 10,
			},
			{
				id: "public",
				caption: "Public sale",
				value: 1,
			},
			{
				id: "ecosystem",
				caption: "Ecosystem",
				value: 10,
			},
			{
				id: "team",
				caption: "Team",
				value: 24,
			},
			{
				id: "maketing",
				caption: "Marketing & advisors",
				value: 5,
			},
			{
				id: "staking",
				caption: "Staking",
				value: 10,
			},
			{
				id: "ingame",
				caption: "In-game rewards",
				value: 30,
			},
		]

	bubbles
		: string[]
		= [
			"medium",
			"medium",
			"smaller",
			"medium",
			"smaller",
			"small",
			"medium",
			"large",
		]

	componentDidMount() {
		window.addEventListener("resize", this.resize)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize)
	}

	resize = () => {
		if (Meta.screenConditions.mobileLarge)
			this.forceUpdate()
	}

	highlight = (
		id: string
	) => {
		this.setState({
			hightlighted: id,
		})
	}

	unhighlight = () => {
		this.setState({
			hightlighted: undefined,
		})
	}

	render() {
		const { hightlighted } = this.state
		return <>
			<section
				id="tokenomics"
				className="c-home-tokenomics"
				style={Meta.screenConditions.mobileLarge && typeof window != "undefined"
					? {
						marginBottom: `-${505 * (1 - window.innerWidth / 550)}px`
					}
					: undefined
				}
			>
				<div className="hts-content">
					<h2>
						<GradientText>
							Tokenomics
						</GradientText>
					</h2>
					{/* <p>
						<strong>$MPR</strong> is a governance token Limited supply 1B $MPR Will allow players <br />to participate in the governance of the MeetNPlay via Decentralised Autonomous Organization (DAO). Can be burned by purchasing services and extensions on MeetNPlay platform
					</p> */}
					<ul className="hts-list">
						<li className="primary">
							<span>
								Total token supply
							</span>
							<span>
								1 000 000 000 $MSW
							</span>
						</li>
						{this.items.map(item => {
							const state = !hightlighted
								? ""
								: item.id == hightlighted
									? "highlighted"
									: "dimmed"

							return <li
								key={item.id}
								className={state}
								onMouseEnter={() => this.highlight(item.id)}
								onMouseLeave={this.unhighlight}
							>
								<span>
									{item.caption}
								</span>
								<span className="value">
									{item.value} %
								</span>
							</li>
						})}
					</ul>

					<div
						className={`hts-map ${hightlighted ? "has-highlight" : ""}`}
						style={Meta.screenConditions.mobileLarge && typeof window != "undefined"
							? {
								transform: `scale(${window.innerWidth / 550})`
							}
							: undefined
						}
					>

						<div className="orbit asteroid-wide">
							<img src={Routing.static("images/fx/drop_2.png")} />
						</div>
						<div className="orbit asteroid-semiwide">
							<img src={Routing.static("images/fx/drop_1.png")} />
						</div>
						<div className="orbit asteroid-medium">
							<img src={Routing.static("images/fx/drop_5.png")} />
						</div>
						<div className="orbit asteroid-small">
							<img src={Routing.static("images/fx/drop_5.png")} />
						</div>

						<div className="orbit short">
							<div className="bubble x-small" />
						</div>
						<div className="orbit wide">
							<div className="bubble x-small" />
						</div>

						<div className={`absolute-bubbles`}>
							{this.items.map((item, i) => {
								const isHighlighted = item.id == hightlighted
								return <div
									key={item.id}
									className={`bubble ${this.bubbles[i]} ${isHighlighted ? "highlighted" : ""}`}
									onMouseEnter={() => this.highlight(item.id)}
									onMouseLeave={this.unhighlight}
								>
									<span className="value">
										{item.value}%
									</span>
									<span className="caption">
										{item.caption.split("&")[0].trim()}
									</span>
								</div>
							})}
						</div>
					</div>
				</div>
			</section>
		</>
	}
}