import React from "react"

import "styles/views/home/components/sections/overview-mobile"

import { overviewItems } from "./Overview"
import ScalableImage from "components/Utils/ScalableImage"
import GradientText from "components/Utils/GradientText"
import AnimatedAccordion from "components/Utils/AnimatedAccordion"

export interface HomeOverviewMobileSectionProps {

}

export interface HomeOverviewMobileSectionState {
	expanded: boolean[]
}

export default
class HomeOverviewMobileSection
extends React.Component<HomeOverviewMobileSectionProps, HomeOverviewMobileSectionState> {
	items = overviewItems

	state
		: HomeOverviewMobileSectionState
		= {
			expanded: this.items.map((_, i) => i == 0)
		}

	get lastExpanded() {
		return this.state.expanded.lastIndexOf(true)
	}

	get progressHeight(): number {
		if (typeof document == "undefined")
			return 0

		const li = [...document.querySelectorAll<HTMLDivElement>("div.hosm-list-item")].slice(0, this.lastExpanded + 1)
		if (li.length < 2)
			return 0

		const headerSize = li[0].getElementsByTagName("header")[0]?.offsetHeight || 0
		return li.reduce((acc, item, i) => {
			const isExpanded = this.state.expanded[i]
			const itemHeight = (isExpanded
				? headerSize + (item.querySelector(".accordion")?.scrollHeight || 0)
				: headerSize
			) + parseFloat(getComputedStyle(item).marginBottom) + (isExpanded ? 24 : 0)

			console.log(
				headerSize,
				item.querySelector(".accordion")?.scrollHeight,
				parseFloat(getComputedStyle(item).marginBottom),
				(isExpanded ? 24 : 0)
			)

			return acc + (
				i == 0
					? itemHeight - headerSize / 2
					: i == li.length - 1
						? headerSize / 2
						: itemHeight
			)
		}, 0)
	}

	toggle = (
		index: number
	) => {
		const { expanded } = this.state
		expanded[index] = !expanded[index]
		this.setState({
			expanded,
		})
	}		

	render() {
		const { expanded } = this.state
		const { lastExpanded } = this
		
		return <>
			<div id="features" />
			<section className="c-home-overview-mobile">
				<div className="hosm-inner-wrapper">
					<div className="hosm-list">
						{overviewItems.map((item, i) => {
							const hasExpandedAfter = i < lastExpanded
							const isExpanded = expanded[i]

							return <div
								key={i}
								className={`hosm-list-item ${hasExpandedAfter || isExpanded ? "visually-expanded" : ""}`}
							>
								<header
									onClick={() => this.toggle(i)}
								>
									<span className="dot">
										<ScalableImage
											path="star_green"
										/>
									</span>
									<h2>
										<GradientText>
											{item.title}
										</GradientText>
									</h2>
									<div className={`toggler ${isExpanded ? "toggled" : ""}`}>
										<ScalableImage
											path="chevron"
										/>
									</div>
								</header>
								<AnimatedAccordion
									easing="easeOutQuart"
									expanded={isExpanded}
									className={`accordion ${isExpanded ? "expanded" : ""}`}
									preventUnmounting
								>
									<div className="content">
										{item.content}
									</div>
									<div className="graphics">
										{[...Array(2)].map((_, j) => {
											return <ScalableImage
												key={j}
												path={`mockups/step_${i * 2 + j + 1}`}
												scales={[2]}
											/>
										})}
									</div>
								</AnimatedAccordion>
							</div>
						})}
						<span
							className="progress"
							style={{ height: this.progressHeight }}
						/>
					</div>
				</div>
			</section>
		</>
	}
}