import React from "react"

import { HOSSceneProps } from "../sections/Overview"
import { HOSKeyframe, interpolate } from "."

export default
class HOSSwipe
extends React.Component<HOSSceneProps, any> {
	keyframes
		: HOSKeyframe[]
		= [
			[0, {
				top: [515],
				left: [170],
				width: [0],
			}],
			[.10, {
				top: [515],
				left: [170],
				width: [0],
			}],
			[.20, {
				top: [515],
				left: [170],
				width: [120, "easeOutCubic"],
			}],
			[.30, {
				top: [515],
				left: [290, "easeOutCubic"],
				width: [0, "easeOutCubic"],
			}],
		]

	render() {
		const {
			top: _top,
			left: _left,
			width: _width,
		} = interpolate(this.props.progress, this.keyframes)

		const [ top ] = _top!
		const [ left ] = _left!
		const [ width ] = _width!

		return <>
			<div
				className="swipe"
				style={{
					position: "absolute",
					top,
					left,
					width,
				}}
			/>
		</>
	}
}