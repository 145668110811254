import React from "react"
import { observer } from "mobx-react"

import "styles/views/home/components/sections/we-made-this"

import GradientText from "components/Utils/GradientText"
import CreatorCard from "../CreatorCard"
import Meta from "stores/Meta"
import Autoscroller from "components/Utils/Autoscroller"
import ScalableImage from "components/Utils/ScalableImage"

export interface HomeWeMadeThisSectionProps {

}

export interface HomeWeMadeThisSectionState {

}

export type CreatorData = {
	avatar: string
	name: string
	label: string
	description: React.ReactNode
}

@observer
export default
class HomeWeMadeThisSection
extends React.Component<HomeWeMadeThisSectionProps, HomeWeMadeThisSectionState> {
	items
		: (CreatorData | "-" | "S")[]
		= [
			"-",
			"-",
			{
				avatar: "c_0",
				name: "Viktor C.",
				label: "CEO",
				description: <>
					Serial entrepreneur <br />
					9 years of experience in Product Management. Built 4 successful startups on С-Level positions
				</>,
			},
			{
				avatar: "c_1",
				name: "Dmytro H.",
				label: "CSO",
				description: <>
					Serial entrepreneur, <br />
					Crypto Enthusiast, <br />
					Successfully built and sold 3 businesses
				</>,
			},
			{
				avatar: "c_2",
				name: "Andrew K.",
				label: "Frontend / Mobile Lead",
				description: <>
					Senior Frontend/Mobile developer, Solid expertise in building complex Web 3.0/Web 2.0 applications
				</>,
			},
			{
				avatar: "c_3",
				name: "Serhii V.",
				label: "CTO",
				description: <>
					Crypto Enthusiast, Senior Blockchain and Backend Developer,
					4 years in managing tech teams
				</>,
			},
			"S",
			{
				avatar: "c_4",
				name: "Yuliia S.",
				label: "Head of Design",
				description: <>
					Solid expertise in Leading design teams and building concepts for variety of advanced Web and Mobile Applications
				</>,
			},
		]

	pureItems = this.items.filter(item => typeof item != "string") as CreatorData[]

	render() {
		return <>
			<section id="team" className="c-we-made-this">
				{/* <div className="__debug_center"></div> */}
				<div className="hwmt-content">
					<h2>
						<GradientText>
							We made this
						</GradientText>
					</h2>
					{Meta.screenConditions.mobileLarge
						? <>
							<Autoscroller
								items={this.pureItems}
								params={{
									itemWidth: 60,
									spacing: 20,
								}}
								renderer={(item, isActive) => {
									return <CreatorCard
										data={item}
										forceInfo={isActive}
										preserveSpaceForInfo
									/>
								}}
							/>
							<div className="helpers">
								<ScalableImage
									path="rarr"
								/>
								<div className="st">
									<GradientText>
										Stronger together <span>💪</span>
									</GradientText>
								</div>
								<ScalableImage
									path="larr"
								/>
							</div>
						</>
						: <div className="hwmt-grid">
							{this.items.map((item, i) => {
								return item == "-"
									? <div key={i} className="hwmt-empty" />
									: item == "S"
										? <div
											key={i}
											className="hwmt-empty st"
										>
											<GradientText>
												Stronger<br />together <span>💪</span>
											</GradientText>
										</div>
										: <CreatorCard
											key={i}
											data={item}
										/>
							})}
						</div>
					}
				</div>
			</section>
		</>
	}
}