import GradientText from "components/Utils/GradientText"
import React from "react"
import Routing from "stores/Routing"

import "styles/views/home/components/sections/overview"
import HOSCursor from "../features/Cursor"
import HOSSwipe from "../features/Swipe"
import HOSRipple from "../features/Ripple"
import HOSMockup from "../features/Mockup"
import { Scroller } from "utils/entities/Scroller"
import { Animator } from "utils/entities/Animator"

export interface HOSSceneProps {
	progress: number
}

export interface HomeOverviewSectionProps {

}

export interface HomeOverviewSectionState {
	progress: number
}

export const overviewItems: {
	caption: string
	title: string
	content: React.ReactNode
}[] = [
	{
		caption: "Meet like-minded people",
		title: "Meet like-minded people",
		content: <p>
			MeetSquad helps you find new friends with common hobbies, interests or relevant experiences. 
			<br /><br />
			It has never been so easy to find a business partner, colleagues for your project or just a person to play basketball tonight!
		</p>,
	},
	{
		caption: "Chat and arrange a meeting",
		title: "Chat and arrange a meeting",
		content: <p>
			A convenient messenger will allow you to contact directly, and arrange or deny a meeting.
		</p>,
	},
	{
		caption: "Meet in person and get rewarded",
		title: "Meet in person and get rewarded",
		content: <p>
			For each meeting, participants will receive $MSW tokens from the daily pool.
			<br /><br />
			Not only will you expand your network, but you will also be rewarded!
		</p>,
	},
	{
		caption: "Level up and meet Influencers",
		title: "Level up and meet Influencers",
		content: <p>
			Keep expanding your network, reach higher Levels, and access hundreds of TOP Influencers, Crypto Bloggers, or Executives.
			<br /><br />
			Nobody is stopping you from becoming a famous crypto enthusiast!
		</p>,
	},
]

export default
class HomeOverviewSection
extends React.Component<HomeOverviewSectionProps, HomeOverviewSectionState> {
	max
		: number
		= 1.25

	scroller
		= new Scroller()

	animator
		= new Animator()

	state
		: HomeOverviewSectionState
		= {
			progress: 0
		}

	items = overviewItems

	factor
		: number
		= 1 / (this.items.length - 1)

	wrapper
		: HTMLElement | null

	get overallSize(): number {
		return window.innerHeight * (this.items.length - 1)
	}

	get cappedProgress(): number {
		const { progress } = this.state
		return progress > 1 ? 1 : progress
	}

	get currentItem(): number {
		return Math.floor(this.cappedProgress / this.factor)
	}

	componentDidMount() {
		document.addEventListener("scroll", this.scroll)
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scroll)
	}

	scroll = () => {
		if (!this.wrapper)
			return

		const { top } = this.wrapper.getBoundingClientRect()
		let progress = -top / this.overallSize
		progress = progress < 0 
			? 0 
			: progress > this.max 
				? this.max 
				: progress
		
		if (progress <= 0 && this.state.progress <= 0)
			return
		if (progress >= this.max && this.state.progress >= this.max)
			return

		this.setState({ progress })
	}

	scrollToNext = () => {
		if (document.documentElement.classList.contains("__nosmooth"))
			return // means animation is still in progress

		const nextItem = this.currentItem + 1

		if (!this.items[nextItem])
			return
		if (!this.wrapper)
			return

		const { top } = this.wrapper.getBoundingClientRect()
		const to
			= this.scroller.scrollTop 
			+ top 
			+ this.overallSize * (this.factor * nextItem + .06)

		document.documentElement.classList.add("__nosmooth")
		this.animator.start({
			from: this.scroller.scrollTop,
			to,
			duration: 6000,
			timingFunction: "easeOutQuad",
			onAnimationEnd: () => {
				document.documentElement.classList.remove("__nosmooth")
			}
		}, value => this.scroller.scrollTop = value)
	}

	render() {
		const { progress } = this.state
		const activeItem = this.items[this.currentItem]
		return <>
			<div id="features" />
			<section
				ref={r => this.wrapper = r}
				className="c-home-overview"
			>
				<div className="hos-inner-wrapper">
					<div className="hos-content-wrapper">
						<div className="hos-steps-wrapper">
							<div className="hos-progress">
								<span style={{ height: `${100 * this.cappedProgress}%`}} />
							</div>
							<ul className="hos-steps">
								{this.items.map((item, i) => {
									const isVisited = i <= this.currentItem
									return <li
										key={i}
										className={`hos-step ${isVisited ? "visited" : ""}`}
									>
										<span className="hos-point">
											<img src={Routing.static("images/star_green.png")} />
										</span>
										<p>
											{item.caption}
										</p>
									</li>
								})}
							</ul>
						</div>
						<div
							key={this.currentItem}
							className="hos-current u-fade-in"
						>
							<h2>
								<GradientText>
									{activeItem.title}
								</GradientText>
							</h2>
							<div className="hos-current-content">
								{activeItem.content}
							</div>
							{this.currentItem < this.items.length - 1 &&
								<span
									className="hos-next u-link"
									onClick={this.scrollToNext}
								>
									What else?
								</span>
							}
						</div>
					</div>
					<div className="hos-scene">
						<HOSMockup progress={progress} />
						<HOSSwipe progress={progress} />
						<HOSRipple progress={progress} />
						<HOSCursor progress={progress} />
					</div>
				</div>
			</section>
		</>
	}
}