import React from "react"

import { HOSSceneProps } from "../sections/Overview"
import { HOSKeyframe, interpolate } from "."
import ScalableImage from "components/Utils/ScalableImage"


export default
class HOSMockup
extends React.Component<HOSSceneProps, any> {
	keyframes
		: HOSKeyframe[]
		= [
			[0, {
				opacity: [1],
				blur: [0],
			}],
			[.6, {
				opacity: [1],
				blur: [0],
			}],
			[.66, {
				opacity: [0, "easeOutCubic"],
				blur: [24, "easeOutCubic"],
			}],
			[.72, {
				opacity: [1, "easeInCubic"],
				blur: [0, "easeInCubic"],
			}],
			[.94, {
				opacity: [1],
				blur: [0],
			}],
			[1, {
				opacity: [0, "easeOutCubic"],
				blur: [24, "easeOutCubic"],
			}],
			[1.06, {
				opacity: [1, "easeInCubic"],
				blur: [0, "easeInCubic"],
			}],
		]

	get mockup(): string {
		const { progress } = this.props
		switch (true) {
			case progress >= 1:
				return "step_8"
			case progress >= .84:
				return "step_6"
			case progress >= .66:
				return "step_5"
			case progress >= .47:
				return "step_4"
			case progress >= .34:
				return "step_3"
			case progress >= .21:
				return "step_2"
			default:
				return "step_1"
		}
	}

	render() {
		const {
			opacity,
			blur,
		} = interpolate(this.props.progress, this.keyframes)

		return <>
			<ScalableImage
				path={`mockups/${this.mockup}`}
				scales={[2]}
				className="mockup"
				style={{
					opacity: opacity![0],
					filter: `blur(${blur![0]}px)`
				}}
			/>
		</>
	}
}