import React from "react"

import "styles/components/utils/autoscroller"

import { Animator } from "utils/entities/Animator"
import { Scroller } from "utils/entities/Scroller"

export interface AutoscrollerProps<T> {
	items: T[]
	params?: {
		/**
		 * Each item width measured in viewport width units (`vw`)  
		 * Default is 100
		 */
		itemWidth?: number
		/**
		 * Space between items in `px`  
		 * Default is 20
		 */
		spacing?: number
	}

	renderer: (
		item: T,
		isActive: boolean
	) => React.ReactNode
}

export interface AutoscrollerState {
	current: number
	isTouched: boolean
	isAnimating: boolean
}

export default
class Autoscroller<T>
extends React.Component<AutoscrollerProps<T>, AutoscrollerState> {
	animator
		: Animator
		= new Animator()

	scroller
		: Scroller

	wrapper
		: HTMLDivElement | null

	start
		: {
			position: number
			time: number
		}
		= {
			position: 0,
			time: 0,
		}

	state
		: AutoscrollerState
		= {
			current: 0,
			isTouched: false,
			isAnimating: false,
		}

	get spacing(): number {
		return this.props.params?.spacing || 20
	}

	get itemWidth(): number {
		return this.props.params?.itemWidth || 100
	}

	get realItemWidth(): number {
		return this.itemWidth / 100 * window.innerWidth
	}

	get itemSize(): number {
		return this.realItemWidth + this.spacing
	}

	componentDidMount() {
		this.scroller = new Scroller(this.wrapper!)
	}

	componentWillUnmount() {
		this.animator.stop()
	}

	scrollTo = (
		currentScroll: number,
		nextIndex: number,
	) => {
		let to = nextIndex * this.itemSize
		if (to < 0)
			to = 0

		this.setState({
			isAnimating: true,
		})
		this.animator.start({
			from: currentScroll,
			to,
			timingFunction: "easeOutQuad",
			onAnimationEnd: () => {
				this.setState({
					current: nextIndex,
					isAnimating: false
				})
			}
		}, value => this.scroller.scrollLeft = value)
	}

	scrollToNearest = (
		currentScroll: number,
	) => {
		const halfSize = this.itemSize / 2

		const nextIndex = [...Array(this.props.items.length)].findIndex((_, i) => {
			const center = this.itemSize * i
			return currentScroll >= (center - halfSize)
				&& currentScroll < (center + halfSize)
		})

		this.scrollTo(currentScroll, nextIndex)
	}

	scrollToSibling = (
		currentScroll: number,
		next: boolean,
	) => {
		const { current } = this.state
		const nextIndex = next
			? current + 1
			: current - 1

		if (!this.props.items[nextIndex])
			return this.scrollToNearest(currentScroll)

		this.scrollTo(currentScroll, nextIndex)
	}

	handleTouchStart = (
		event: React.TouchEvent<HTMLDivElement>
	) => {
		if (this.state.isAnimating)
			this.animator.stop()

		this.start = {
			position: event.touches[0].clientX,
			time: Date.now(),
		}

		this.setState({
			isTouched: true,
		})
	}

	handleTouchEnd = (
		event: React.TouchEvent<HTMLDivElement>
	) => {
		console.log("untouch", this.state.isTouched)
		if (!this.state.isTouched)
			return

		this.setState({
			isTouched: false,
		})

		const { time: timeStart, position: positionStart } = this.start
		const timeEnd = Date.now()
		const positionEnd = event.changedTouches[0].clientX

		const positionDelta = positionEnd - positionStart
		const timeDelta = timeEnd - timeStart

		console.log(positionDelta)

		if (Math.abs(positionDelta) >= 60 && timeDelta <= 500)
			this.scrollToSibling(event.currentTarget.scrollLeft, positionDelta < 0)
		else
			this.scrollToNearest(event.currentTarget.scrollLeft)
	}

	render() {
		const { items } = this.props
		const { current, isAnimating } = this.state
		// const isIPhone = typeof navigator != "undefined" && navigator.userAgent.toLowerCase().includes("iphone")

		return <>
			<div
				ref={r => this.wrapper = r}
				className={`c-autoscroller ${!isAnimating ? "__scrollable" : ""}`}
				onTouchStart={this.handleTouchStart}
				onTouchEnd={this.handleTouchEnd}
				onScroll={event => {
					if (!this.state.isTouched && !this.state.isAnimating)
						event.preventDefault()
				}}
			>
				<div
					className="as-wrapper"
					style={{
						width: `calc(${items.length * this.itemWidth}vw + ${items.length * this.spacing}px)`,
						padding: `20px calc(50vw - ${this.itemWidth / 2}vw - ${this.spacing / 2}px)`,
					}}
				>
					{items.map((item, i) => {
						const isActive = i == current
						return <div
							key={i}
							className="as-item"
							style={{
								padding: `0 ${this.spacing / 2}px`
							}}
						>
							{this.props.renderer(item, isActive)}
						</div>
					})}
				</div>
			</div>
		</>
	}
}