import React from "react"
import { Route, Switch } from "react-router-dom"
import { observer } from "mobx-react"

import "styles/fonts"
import "styles/animations"
import "styles/main"
import "styles/uni"

import Routing from "stores/Routing"

import Header from "components/Header"
import Footer from "components/Footer"

import HomeView from "views/Home"
import Whitelist from "stores/Whitelist"
import WhitelistModal from "components/Modals/Whitelist"
import Parallax from "stores/Parallax"
import ParallaxLayerBottom from "components/Parallax/instances/LayerBottom"
import ParallaxLayerDust from "components/Parallax/instances/LayerDust"

export interface AppProps {

}

export interface AppState {

}

@observer
export default
class App
extends React.Component<AppProps, AppState> {
	componentDidMount() {
		Parallax
		Parallax.subscribe()
	}

	render() {
		return <>
			<Header />
			<ParallaxLayerBottom />
			<Switch>
				<Route
					path={Routing.paths.home}
					exact
					component={HomeView}
				/>
			</Switch>
			<Footer />
			<ParallaxLayerDust />
			{Whitelist.isShown &&
				<WhitelistModal
					onClose={Whitelist.hide}
				/>
			}
		</>
	}
}