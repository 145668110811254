import { EasingFunction, EasingFunctions, FRAME_DURATION_60 } from "utils/animation"

export interface AnimatorStartProps {
	from: number
	to: number
	duration?: number
	timingFunction?: EasingFunction
	onAnimationEnd?: () => void
}

export class Animator {
	private frame
		: number

	private animationEndHandler?
		: () => void

	start = (
		props: AnimatorStartProps,
		assigner: (
			value: number
		) => void,
	) => {
		const {
			from,
			to,
			duration = 350,
			timingFunction = "linear",
		} = props

		this.animationEndHandler = props.onAnimationEnd

		let timing = 0
		const diff = to - from

		const animate = () => {
			if (timing >= duration) {
				assigner(to)
				return this.stop()
			}

			timing += FRAME_DURATION_60
			const rel = EasingFunctions[timingFunction](timing / duration)
			assigner(from + diff * rel)
			this.frame = requestAnimationFrame(animate)
		}
		animate()
	}

	stop = () => {
		cancelAnimationFrame(this.frame)
		this.animationEndHandler?.()
		this.animationEndHandler = undefined
	}
}