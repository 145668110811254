import React from "react"

import { HOSSceneProps } from "../sections/Overview"
import { HOSKeyframe, interpolate } from "."
import ScalableImage from "components/Utils/ScalableImage"


export default
class HOSCursor
extends React.Component<HOSSceneProps, any> {
	keyframes
		: HOSKeyframe[]
		= [
			[0, {
				top: [360],
				left: [-100],
			}],
			[.10, {
				top: [535],
				left: [150, "easeInCubic"]
			}],
			[.20, {
				top: [535],
				left: [250, "easeOutCubic"],
			}],
			[.32, {
				top: [415],
				left: [200, "easeOutCubic"],
			}],
			[.38, {
				top: [415],
				left: [200],
			}],
			[.45, {
				top: [345],
				left: [180, "easeOutCubic"],
			}],
			[.75, {
				top: [345],
				left: [180],
			}],
			[.82, {
				top: [120],
				left: [220, "easeInCubic"],
			}],
			[1, {
				top: [120],
				left: [220],
			}],
			[1.06, {
				top: [150],
				left: [240, "easeInCubic"],
			}],
		]

	render() {
		const {
			top: _top,
			left: _left,
		} = interpolate(this.props.progress, this.keyframes)

		const [ top ] = _top!
		const [ left ] = _left!

		return <>
			<ScalableImage
				path="pointer"
				className="cursor"
				style={{
					position: "absolute",
					width: 37,
					height: 49,
					top,
					left,
				}}
			/>
		</>
	}
}