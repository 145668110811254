import React from "react"

export interface GradientTextProps {
	className?: string
	children?: React.ReactNode
}

export interface GradientTextState {

}

export default
class GradientText
extends React.Component<GradientTextProps, GradientTextState> {
	render() {
		return <>
			<span className={`u-gradient-text ${this.props.className || ""}`}>
				{this.props.children}
			</span>
		</>
	}
}