import React from "react"

import "styles/components/parallax/instances/layer-bottom"

import ParallaxLayer from "../Layer"

export default
class ParallaxLayerBottom
extends React.Component<{}, {}> {
	render() {
		return <>
			<ParallaxLayer
				scrollFactor={.25}
			>
				{[...Array(9)].map((_, i) => {
					return <div
						key={i}
						className="c-pl-bottom-item"
					/>
				})}
			</ParallaxLayer>
		</>
	}
}