import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"
import Helmet from "react-helmet"

import "styles/views/home"
import HomeMainSection from "./components/sections/Main"
import HomeDescriptionSection from "./components/sections/Description"
import HomeOverviewSection from "./components/sections/Overview"
import HomeOverviewMobileSection from "./components/sections/OverviewMobile"
import HomeMotivationSection from "./components/sections/Motivation"
import HomeTokenomicsSection from "./components/sections/Tokenomics"
import HomeJoinWhitelistSection from "./components/sections/JoinWhitelist"
// import HomeRoadmapSection from "./components/sections/Roadmap"
import HomeRoadmapTempSection from "./components/sections/RoadmapTemp"
import HomeComingSoonSection from "./components/sections/ComingSoon"
import HomeWeMadeThisSection from "./components/sections/WeMadeThis"
import ParallaxLayerBigDrops from "components/Parallax/instances/LayerBigDrops"
import Meta from "stores/Meta"

export interface HomeViewProps
extends RouteComponentProps {

}

export interface HomeViewState {

}

@observer
export default
class HomeView
extends React.Component<HomeViewProps, HomeViewState> {
	render() {
		return <>
			<Helmet>
				<title>
					MeetSquad – a smooth way to grow your crypto network and find like-minded individuals
				</title>
			</Helmet>
			<main className="v-home u-fade-in">
				<HomeMainSection />
				<HomeDescriptionSection />

				<ParallaxLayerBigDrops />
				{Meta.screenConditions.tabletBook
					? <HomeOverviewMobileSection />
					: <HomeOverviewSection />
				}
				<HomeMotivationSection />
				<HomeTokenomicsSection />
				<HomeJoinWhitelistSection />
				{/* <HomeRoadmapSection /> */}
				<HomeRoadmapTempSection />
				<HomeComingSoonSection />
				<HomeWeMadeThisSection />
			</main>
		</>
	}
}