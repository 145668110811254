import React from "react"
import { observer } from "mobx-react"

import "styles/components/parallax/layer"
import Parallax from "stores/Parallax"

export interface ParallaxLayerProps {
	scrollFactor?: number
	children?: React.ReactNode
}

export interface ParallaxLayerState {

}

@observer
export default
class ParallaxLayer
extends React.Component<ParallaxLayerProps, ParallaxLayerState> {
	render() {
		const { scrollFactor = 1, children } = this.props

		return <>
			<div className="c-parallax-layer">
				<div
					className="pl-content"
					style={{
						transform: `translateY(${-Parallax.top * scrollFactor}px) translateZ(0)`
					}}
				>
					{children}
				</div>
			</div>
		</>
	}
}