import GradientText from "components/Utils/GradientText"
import ScalableImage from "components/Utils/ScalableImage"
import React from "react"
import Routing from "stores/Routing"

import "styles/views/home/components/sections/roadmap-temp"

export interface HomeRoadmapTempSectionProps {

}

export interface HomeRoadmapTempSectionState {
	highlight?: number
}

/*

Partner with 50 Ambassadors
Build community of 20000+ people
Reach 15000+ active users

*/

export default
class HomeRoadmapTempSection
extends React.Component<HomeRoadmapTempSectionProps, HomeRoadmapTempSectionState> {
	state
		: HomeRoadmapTempSectionState
		= {
			highlight: undefined,
		}

	items
		: {
			quater: string,
			items: React.ReactNode[]
		}[]
		= [
			{
				quater: "Q2 24",
				items: [
					<>Complete <span>MVP</span></>,
					<>Test <span>product-market-fit</span></>,
					<>Build community of <span>1000+ people</span></>,
				],
			},
			{
				quater: "Q3 24",
				items: [
					<>Partner with <span>10 influencers</span></>,
					<>Define <span>development scope</span> based on users feedback</>,
					<>Build <span>financial</span> assumptions</>,
					<>Build community of <span>5000+ people</span></>,
					<>Reach <span>1000+ active users</span></>,
				],
			},
			{
				quater: "Q4 24",
				items: [
					<><span>Finalise</span> Product development</>,
					<>Build community of <span>10000+ people</span></>,
					<>Reach <span>5000+ active users</span></>,
					<><span>Private sale</span></>,
				],
			},
			{
				quater: "Q1 25",
				items: [
					<>Partner with <span>50 Influencers</span></>,
					<>Build community of <span>20000+ people</span></>,
					<>Reach <span>15000+ active users</span></>,
				],
			},
			{
				quater: "Q3 25",
				items: [
					<>$MSW Public Sale</>,
				],
			},
		]

	highlight = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		this.setState({
			highlight: event.currentTarget.getBoundingClientRect().left,
		})
	}

	unhighlight = () => {
		this.setState({
			highlight: undefined,
		})
	}
		
	render() {
		return <>
			<section id="roadmap" className="c-home-roadmap-temp">
				<span className="hrts-progress">
					<span
						className="potential"
						style={{
							width: this.state.highlight || 0
						}}
					/>
					<span className="current" />
				</span>
				<div className="hrts-content">
					<ScalableImage
						path="star_green"
						className="star"
					/>
					<h2>
						<GradientText>
							Roadmap
						</GradientText>
					</h2>
					
					<div className="hrts-items">
						{this.items.map((item, i) => {
							return <div
								key={i}
								className="hrts-item"
								onMouseEnter={this.highlight}
								onMouseLeave={this.unhighlight}
							>
								<h3>
									{item.quater}
								</h3>
								<img src={Routing.static("images/clubs.png")} />
								<ul>
									{item.items.map((value, j) => {
										return <li
											key={j}
										>
											{value}
										</li>
									})}
								</ul>
							</div>
						})}
					</div>
				</div>
			</section>
		</>
	}
}