import { EasingFunction, EasingFunctions } from "utils/animation"

type HOSChangableValue = [number] | [number, EasingFunction]
type HOSChangableParams =
	| "top"
	| "left"
	| "right"
	| "bottom"
	| "width"
	| "height"
	| "opacity"
	| "blur"
	| "innerBoxShadow"
	| "scale"

export type HOSChangable = {
	[key in HOSChangableParams]?: HOSChangableValue
}

export type HOSKeyframe = [number, HOSChangable]

export const interpolate = (
	progress: number,
	keyframes: HOSKeyframe[],
): HOSChangable => {
	let keyframeIndex = keyframes.findIndex(item => item[0] > progress)
	if (keyframeIndex < 0) {
		return keyframes.slice(-1)[0][1]
	}

	const [ startFrame, fromValues ] = keyframes[keyframeIndex - 1]
	const [ endFrame, toValues ] = keyframes[keyframeIndex]
	const result = JSON.parse(JSON.stringify(fromValues)) as HOSChangable
	const rel = (progress - startFrame) / (endFrame - startFrame)

	Object.keys(fromValues).forEach((key: keyof HOSChangable) => {
		const [fromValue] = fromValues[key]!
		const [toValue, easing = "linear"] = toValues[key]!
		const diff = toValue - fromValue
		result[key]![0] = EasingFunctions[easing](rel) * diff + fromValue
	})

	return result
}