import React from "react"
import Routing from "stores/Routing"

export interface ScalableImageProps {
	className?: string
	path: string
	ext?: string
	alt?: string
	scales?: number[]
	style?: React.CSSProperties
}

export interface ScalableImageState {

}

export default
class ScalableImage
extends React.Component<ScalableImageProps, ScalableImageState> {
	render() {
		const { path, ext = "png", scales: sizes = [2, 3] } = this.props
		return <>
			<img
				className={this.props.className}
				style={this.props.style}
				alt={this.props.alt}
				src={Routing.static(`images/${path}.${ext}`)}
				srcSet={sizes.map(size => {
					return Routing.static(`images/${path}@${size}x.${ext}`) + " " + `${size}x`
				}).join(", ")}
			/>
		</>
	}
}