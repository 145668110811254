import React from "react"

import "styles/components/parallax/instances/layer-dust"

import Routing from "stores/Routing"

import { randomInt } from "utils/dev"

import ParallaxLayer from "../Layer"

export default
class ParallaxLayerDust
extends React.Component<{}, {}> {
	step
		: number
		= 289

	itemsLow = [...Array(26)].map((_, i) => {
		const name = i % 4 == 0 ? "drop_3" : "drop_4"
		return <img
			key={i}
			className="c-pl-dust-item"
			src={Routing.static(`images/fx/${name}.png`)}
			style={{
				top: (i + 2) * this.step,
				...(i % 2 == 0
					? { left: randomInt([window.innerWidth / 5, window.innerWidth / 2]) }
					: { right: randomInt([window.innerWidth / 5, window.innerWidth / 2]) }
				),
				animationDelay: -(randomInt([50, 450])) + "ms",
				animationDuration: randomInt([3600, 6000]) + "ms",
			}}
		/>
	})

	itemsHigh = [...Array(20)].map((_, i) => {
		const name = "drop_4"

		return <img
			key={i}
			className="c-pl-dust-item op"
			src={Routing.static(`images/fx/${name}.png`)}
			style={{
				top: (i + 1) * this.step * 2,
				...(i % 2 == 0
					? { left: randomInt([window.innerWidth / 6, window.innerWidth / 2.5]) }
					: { right: randomInt([window.innerWidth / 6, window.innerWidth / 2.5]) }
				),
				animationDelay: -(randomInt([50, 450])) + "ms",
				animationDuration: randomInt([2400, 4800]) + "ms",
			}}
		/>
	})

	render() {
		return <>
			<ParallaxLayer
				scrollFactor={.78}
			>
				{this.itemsLow}
			</ParallaxLayer>
			<ParallaxLayer
				scrollFactor={1.2}
			>
				{this.itemsHigh}
			</ParallaxLayer>
		</>
	}
}