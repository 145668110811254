class Routing {
	readonly paths = {
		home: "/"
	}

	static = (
		path: string
	) => {
		return `/static/${path}`
	}
}

export default new Routing()