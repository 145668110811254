import React from "react"
import Main from "stores/Main"
import Routing from "stores/Routing"

import "styles/components/footer"
import GradientText from "./Utils/GradientText"

export interface FooterProps {

}

export interface FooterState {

}

export default
class Footer
extends React.Component<FooterProps, FooterState> {
	links
		: {
			title: string
			items: {
				caption: string
				to: string
				disabled?: boolean
				external?: boolean
			}[]
		}[]
		= [
			{
				title: "Explore",
				items: [
					{
						caption: "Features",
						to: "#features",
					},
					{
						caption: "Tokenomics",
						to: "#tokenomics",
					},
					{
						caption: "Roadmap",
						to: "#roadmap",
					},
				],
			},
			{
				title: "Learn",
				items: [
					{
						caption: "White paper",
						to: "kappa",
						disabled: true,
					},
					{
						caption: "Medium",
						to: "kappa",
						disabled: true,
					},
				],
			},
			{
				title: "Social media",
				items: [
					{
						caption: "Twitter",
						to: "https://twitter.com/meet_squad_",
						external: true,
						// disabled: true,
					},
					{
						caption: "Discord",
						to: "kappa",
						disabled: true,
					},
				],
			},
			// {
			// 	title: "Links",
			// 	items: [
			// 		{
			// 			caption: "Privacy Policy",
			// 			to: "kappa",
			// 			disabled: true,
			// 		},
			// 		{
			// 			caption: "Terms of Use",
			// 			to: "kappa",
			// 			disabled: true,
			// 		},
			// 	],
			// },
		]

	render() {
		return <>
			<footer className="c-footer">
				<div className="fo-inner-wrapper">
					<div className="left">
						<img
							src={Routing.static("images/logo.svg")}
							alt={Main.projectName}
						/>
						<span className="copyright">
							&copy; {new Date().getFullYear()} {Main.projectName} LTD. All rights reserved.
						</span>
					</div>
					<div className="right">
						{this.links.map((group, i) => {
							return <div
								key={i}
								className="column"
							>
								<div className="header">
									<GradientText>
										{group.title}
									</GradientText>
								</div>
								<div className="links">
									{group.items.map((link, j) => {
										return <div
											key={j}
											className="link"
										>
											{link.disabled
												? <div className="u-link disabled">
													{link.caption}
												</div>
												: <a
													href={link.to}
													className="u-link"
													target={link.external ? "_blank" : ""}
													rel="noopener"
												>
													{link.caption}
												</a>
											}
										</div>
									})}
								</div>
							</div>
						})}
					</div>
				</div>
			</footer>
		</>
	}
}