import { action, computed, makeObservable, observable } from "mobx"
import axios from "axios"

class WhitelistStore {
	@observable
	private _isShown
		: boolean
		= false

	@observable
	private _isLoading
		: boolean
		= false

	@action
	private setLoading = (
		value: boolean
	) => {
		this._isLoading = value
	}

	constructor() {
		makeObservable(this)
	}

	@computed
	get isShown() {
		return this._isShown
	}

	@computed
	get isLoading() {
		return this._isLoading
	}

	@action
	show = () => {
		this._isShown = true
	}

	@action
	hide = () => {
		this._isShown = false
		this._isLoading = false
	}

	send = (
		email: string
	): Promise<any> => {
		this.setLoading(true)
		return axios.post(
			"https://oedd9pn778.execute-api.us-west-1.amazonaws.com/dev/whitelist",
			{ email },
		).finally(() => this.setLoading(false))
	}
}

export default new WhitelistStore()