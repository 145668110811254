import SimpleLabel from "components/UI/Labels/Simple"
import React from "react"

import "styles/views/home/components/sections/description"

export interface HomeDescriptionSectionProps {

}

export interface HomeDescriptionSectionState {

}

export default
class HomeDescriptionSection
extends React.Component<HomeDescriptionSectionProps, HomeDescriptionSectionState> {
	labels
		: string[]
		= [
			"🚊 Traveling",
			"🎨 Art",
			"🏃 Running",
			"🏋️ Gym",
			"⚽️ Football",
			"🎭 Theater",
		]

	render() {
		return <>
			<section className="c-home-description">
				<div className="hds-content">
					<p>
						We help to find new🌚friends with the same🏄🏼‍interests, extend crypto network, build community and get rewards for it 🏆
					</p>
					<div className="hds-labels">
						{this.labels.map((label, i) => {
							return <SimpleLabel key={i}>
								{label}
							</SimpleLabel>
						})}
					</div>
				</div>
			</section>
		</>
	}
}