import React from "react"

import "styles/views/home/components/sections/motivation"

import SimpleLabel from "components/UI/Labels/Simple"
import ScalableImage from "components/Utils/ScalableImage"

export interface HomeMotivationSectionProps {

}

export interface HomeMotivationSectionState {

}

export default
class HomeMotivationSection
extends React.Component<HomeMotivationSectionProps, HomeMotivationSectionState> {
	labels1
		: string[]
		= [
			"👥 Bring more enthusiasts in your community",
			"✍️ Directly contact with TOP lvl influencers",
		]

	labels2
		: string[]
		= [
			"🤩 No limitations for you",
			"🗓 Create events and invite your members",
		]

	render() {
		return <>
			<section className="c-home-motivation">
				<div className="hmos-content">
					<h2>
						Build your community
					</h2>
					<p>
						Are you a Crypto Influencer? <br />
						CEO, blogger or investor?
					</p>
					<div className="arrow">
						&darr;
					</div>
					<div className="hmos-labels">
						<div className="hmos-label-row">
							{this.labels1.map((label, i) => {
								return <SimpleLabel key={i}>
									{label}
								</SimpleLabel>
							})}
						</div>
						<div className="hmos-label-row">
							{this.labels2.map((label, i) => {
								return <SimpleLabel key={i}>
									{label}
								</SimpleLabel>
							})}
						</div>
					</div>
					<div className="hmos-graphics">
						<ScalableImage path="mockups/motiv_1" scales={[2]} />
						<ScalableImage path="mockups/motiv_2" scales={[2]} />
					</div>
				</div>
			</section>
		</>
	}
}