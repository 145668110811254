import React from "react"

import "styles/components/header"
import GradientText from "./Utils/GradientText"
import Routing from "stores/Routing"
import Whitelist from "stores/Whitelist"

export interface HeaderProps {

}

export interface HeaderState {
	expanded: boolean
}

export default
class Header
extends React.Component<HeaderProps, HeaderState> {
	state
		: HeaderState
		= {
			expanded: false,
		}

	routes = [
		"features",
		"tokenomics",
		"roadmap",
		"team",
	]

	componentDidMount() {
		document.addEventListener("click", this.hide)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.hide)
	}

	hide = () => {
		this.setState({
			expanded: false,
		})
	}

	toggle = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		event.stopPropagation()
		event.nativeEvent.stopImmediatePropagation()

		this.setState({
			expanded: !this.state.expanded,
		})
	}
	
	render() {
		return <>
			<header className={`c-header ${this.state.expanded ? "expanded" : ""}`}>
				<div className="h-inner-wrapper">
					<img
						className="h-logo"
						src={Routing.static("images/logo.svg")}
						alt="MeetSquad"
					/>

					<div className="h-router">
						{this.routes.map(route => {
							return <a
								key={route}
								className="h-route u-link"
								href={`#${route}`}
							>
								{route}
							</a>
						})}
					</div>

					<div className="h-actions">
						<div
							className="u-gradient-button"
							onClick={Whitelist.show}
						>
							<GradientText className="hoverable">
								Join Whitelist
							</GradientText>
						</div>
					</div>
				</div>
				<div
					className="h-burger"
					onClick={this.toggle}
				>
					<span />
				</div>
			</header>
		</>
	}
}