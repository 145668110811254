import React from "react"
import Helmet from "react-helmet"

import App from "./App"
import Routing from "stores/Routing"

export interface AppProviderProps {

}

export interface AppProviderState {

}

export default
class AppProvider
extends React.Component<AppProviderProps, AppProviderState> {
	render() {
		return <>
			<Helmet>
				<link
					rel="icon"
					type="image/png"
					href={Routing.static(`images/favicon.png`)}
				/>
			</Helmet>
			<App />
		</>
	}
}