import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react"

import "styles/components/modals/whitelist"

import GradientText from "components/Utils/GradientText"
import Whitelist from "stores/Whitelist"

export interface WhitelistModalProps {
	onClose: () => void
}

export interface WhitelistModalState {
	fadeOut?: boolean
	email: string
	success?: boolean
}

@observer
export default
class WhitelistModal
extends React.Component<WhitelistModalProps, WhitelistModalState> {
	validator
		= document.createElement("input")

	state
		: WhitelistModalState
		= {
			email: "",
			fadeOut: false,
			success: undefined
		}

	get isEmailValid(): boolean {
		this.validator.setAttribute("type", "email")
		this.validator.setAttribute("required", "true")
		this.validator.value = this.state.email
		return this.validator.validity.valid
	}

	handleSubmit = () => {
		if (Whitelist.isLoading || typeof this.state.success == "boolean" || !this.isEmailValid)
			return

		Whitelist.send(this.state.email)
			.then(() => {
				console.log("lolg")
				this.setState({
					success: true,
				})
				setTimeout(this.fadeOut, 1000)
			})
			.catch(() => {
				this.setState({
					success: false,
				})
				setTimeout(() => {
					this.setState({
						success: undefined
					})
				}, 1000)
			})
	}

	fadeOut = () => {
		this.setState({
			fadeOut: true,
		})
	}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		if (event.animationName == "whitelistFadeOut")
			this.props.onClose()
	}

	render() {
		const { success } = this.state
		const showProcessing = Whitelist.isLoading || typeof success == "boolean"

		return ReactDOM.createPortal(
			<div
				className={`c-whitelist-modal fade-${this.state.fadeOut ? "out" : "in"}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<div className="wlm-content">
					{!showProcessing &&
						<span
							className="close"
							onClick={this.fadeOut}
						>
							&times;
						</span>
					}
					<h1>
						Join Whitelist
					</h1>
					<p>
						Become the first of worldwide Crypto community with thousands influencers and like-minded people
					</p>
					<div className={`actions-wrapper ${showProcessing ? "processing" : ""}`}>
						<div className="status">
							{Whitelist.isLoading
								? <>
									Processing ⏳
								</>
								: success
									? <>
										Success! ✅
									</>
									: <>
										Failed! ⛔
									</>
							}
						</div>
						<div className="form">
							<input
								type="email"
								name="email"
								value={this.state.email}
								placeholder="Your email"
								onKeyDown={e => e.key == "Enter" && this.handleSubmit()}
								onChange={event => {
									if (showProcessing)
										return
									this.setState({
										email: event.currentTarget.value
									})

								}}
							/>
							<div
								className={`u-simple-button ${!this.isEmailValid ? "disabled" : ""}`}
								onClick={this.handleSubmit}
								title={!this.isEmailValid ? "Please enter a valid email" : undefined}
							>
								<GradientText className="hoverable">
									Join
								</GradientText>
							</div>
						</div>
					</div>
				</div>
			</div>,
			document.body,
		)
	}
}