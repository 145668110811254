import ScalableImage from "components/Utils/ScalableImage"
import React from "react"

import "styles/views/home/components/creator-card"
import { CreatorData } from "./sections/WeMadeThis"

export interface CreatorCardProps {
	data: CreatorData
	forceInfo?: boolean
	preserveSpaceForInfo?: boolean
}

export interface CreatorCardState {

}

export default
class CreatorCard
extends React.Component<CreatorCardProps, CreatorCardState> {
	render() {
		const { data, forceInfo, preserveSpaceForInfo } = this.props
		return <>
			<div className={`c-creator-card ${forceInfo ? "force-info" : ""} ${preserveSpaceForInfo ? "preserve-space" : ""}`}>
				<ScalableImage
					path={`creators/${data.avatar}`}
					scales={[2]}
					alt={data.name}
				/>
				<span className="name">
					{data.name}
				</span>
				<span className="label">
					{data.label}
				</span>
				<div className="info">
					<div className="header">
						{data.name}
					</div>
					<div className="label">
						{data.label}
					</div>
					<p>
						{data.description}
					</p>
				</div>
			</div>
		</>
	}
}