import React from "react"

import "styles/views/home/components/sections/join-whitelist"

import GradientText from "components/Utils/GradientText"
import Whitelist from "stores/Whitelist"

export interface HomeJoinWhitelistSectionProps {

}

export interface HomeJoinWhitelistSectionState {

}

export default
class HomeJoinWhitelistSection
extends React.Component<HomeJoinWhitelistSectionProps, HomeJoinWhitelistSectionState> {
	render() {
		return <>
			<section className="c-home-join-whitelist">
				<div className="hjws-content">
					<h2>
						Join Whitelist and remove limitations <small>👑</small>
					</h2>
					<div
						className="u-simple-button"
						onClick={Whitelist.show}
					>
						<GradientText className="hoverable">
							Join
						</GradientText>
					</div>
				</div>
			</section>
		</>
	}
}