import { action, makeObservable, observable } from "mobx"
import { v4 as uuid } from "uuid"

type ScreenSize =
	| "desktopSmall"
	| "tabletAlbum"
	| "tabletBook"
	| "mobileLarge"
	| "mobileSmall"

class MetaStore {
	private screenSizesMaxWidths
		: {
			[key in ScreenSize]: number
		}
		= {
			desktopSmall: 1300,
			tabletAlbum: 1100,
			tabletBook: 800,
			mobileLarge: 600,
			mobileSmall: 320,
		}

	@action
	private handleResize = () => {
		const screenSizes = Object.keys(this.screenSizesMaxWidths) as ScreenSize[]
		screenSizes.forEach(screen => {
			const maxWidth = this.screenSizesMaxWidths[screen]
			this.screenConditions[screen] = window.innerWidth <= maxWidth
		})
	}

	constructor() {
		makeObservable(this)

		if (typeof window != "undefined") {
			window.addEventListener("resize", this.handleResize)
			this.handleResize()
		}
	}

	@observable
	tabId
		: string
		= uuid()

	@observable
	screenConditions
		: {
			[key in ScreenSize]: boolean
		}
		= {
			desktopSmall: false,
			tabletAlbum: false,
			tabletBook: false,
			mobileLarge: false,
			mobileSmall: false,
		}
}

export default new MetaStore()